import React from "react";
import PropTypes from 'prop-types';
import Button from '../../components/Button';

import Page from "../Page"

const PageError = () => (
  <Page
    head={{
      // hero credit: https://www.flickr.com/photos/mypubliclands/16101654539/
      hero: "https://farm8.staticflickr.com/7559/16101654539_bee5151340_k.jpg",
      title: 'Pagina niet gevonden'
    }}
    hideTitle={true}
    className="page-error"
  >
    <div className="container">
      <div className="text">
        <div className="title">
          <h1>Pagina niet gevonden</h1>
          <p>De pagina die je zoekt kan helaas niet worden gevonden. Misschien is de pagina verwijderd of verplaatst, of heb je de URL verkeerd gespeld.</p>
        </div>
        <Button href="/" buttonCta small>Ga terug naar de homepage</Button>
      </div>
    </div>
  </Page>
)

PageError.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  errorText: PropTypes.string,
}

PageError.defaultProps = {
  error: 404,
  errorText: "Page Not Found",
}

export default PageError
